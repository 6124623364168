import React, { useState, createRef } from "react";
import { useMutation } from "@apollo/react-hooks";
import { MdExpandLess, MdExpandMore, MdEmail } from "react-icons/md";
import { FaDice } from "react-icons/fa";

import { encrypt } from "./Encryption";
import { GET_NOTES, CREATE_NOTE } from "./Queries";
import { generatePassword } from "./generatePassword";

export default function NotesList(props: {
  reloadNotes: CallableFunction;
  addNotification: CallableFunction;
  userEmail: string;
  encryptionkey: string;
}) {
  const [expended, setExpanded] = useState(false);

  let titleField = createRef<HTMLInputElement>();
  let loginField = createRef<HTMLInputElement>();
  let passwordField = createRef<HTMLInputElement>();
  let textField = createRef<HTMLTextAreaElement>();

  const [createNote] = useMutation(CREATE_NOTE, {
    update(cache, { data: { notes } }) {
      const cachedData: any = cache.readQuery({ query: GET_NOTES });
      const cachedNotes = cachedData.notes;
      cache.writeQuery({
        query: GET_NOTES,
        data: { notes: cachedNotes.concat([createNote]) }
      });
    }
  });

  return (
    <div
      className={"lightbeigebg rounded p-2 m-2 mobilefullwidth"}
      style={{
        textAlign: "left",
        height: "min-content",
        minWidth: "380px"
      }}
    >
      <div onClick={e => setExpanded(!expended)}>
        <b>Créer une note</b>
        {!expended && (
          <MdExpandMore className="activeicon" style={{ float: "right" }} />
        )}
        {expended && (
          <MdExpandLess className="activeicon" style={{ float: "right" }} />
        )}
      </div>
      {expended && (
        <form
          onSubmit={e => {
            e.preventDefault();
            let title: string = "";
            let login: string = "";
            let password: string = "";
            let text: string = "";

            if (titleField.current) title = titleField.current.value;
            if (loginField.current) login = loginField.current.value;
            if (passwordField.current) password = passwordField.current.value;
            if (textField.current) text = textField.current.value;

            createNote({
              variables: {
                title: encrypt(title, props.encryptionkey),
                login: encrypt(login, props.encryptionkey),
                password: encrypt(password, props.encryptionkey),
                text: encrypt(text, props.encryptionkey)
              }
            }).then(e => {
              props.reloadNotes();
              setExpanded(false);
              props.addNotification({
                message: "Votre note a été crée",
                level: "success"
              });
            });
          }}
        >
          <div
            style={{ width: "100%" }}
            className="d-flex justify-content-between mt-2"
          >
            <b>Titre</b>
            <input
              className="form-control form-control-sm"
              style={{ width: "200px", marginLeft: 10 }}
              name="title"
              type="text"
              ref={titleField}
            />
          </div>
          <div
            style={{ width: "100%" }}
            className="d-flex justify-content-between mt-2"
          >
            <div style={{ width: "140px" }}>
              Login
              <MdEmail
                style={{ float: "right" }}
                className="activeicon ml-2"
                onClick={e => {
                  if (loginField.current)
                    loginField.current.value = props.userEmail;
                }}
              />
            </div>
            <input
              className="form-control form-control-sm"
              style={{ width: "200px", marginLeft: 10 }}
              name="login"
              type="text"
              ref={loginField}
            />
          </div>
          <div
            style={{ width: "100%" }}
            className="d-flex justify-content-between mt-2"
          >
            <div style={{ width: "140px" }}>
              Mot de passe
              <FaDice
                style={{ float: "right" }}
                className="activeicon ml-2"
                onClick={e => {
                  if (passwordField.current)
                    generatePassword(passwordField.current);
                }}
              />
            </div>
            <input
              className="form-control form-control-sm"
              style={{ width: "200px", marginLeft: 10 }}
              name="password"
              type="text"
              ref={passwordField}
            />
          </div>
          <div
            style={{ width: "100%" }}
            className="d-flex justify-content-between mt-2"
          >
            Texte
            <textarea
              className="form-control form-control-sm"
              style={{ width: "200px", marginLeft: 10 }}
              name="text"
              ref={textField}
            />
          </div>
          <div
            style={{ width: "100%" }}
            className="d-flex justify-content-between mt-2"
          >
            <input
              className="btn btn-primary"
              style={{ width: "150px", marginLeft: "auto" }}
              value="Create"
              type="submit"
            />
          </div>
        </form>
      )}
    </div>
  );
}
