var AES = require("crypto-js/aes");
var SHA256 = require("crypto-js/sha256");
var encUtf8 = require("crypto-js/enc-utf8");
var encHex = require("crypto-js/enc-hex");

export function encrypt(text: string, key: string) {
  return AES.encrypt(text, key).toString();
}

export function decrypt(text: string, key: string) {
  return AES.decrypt(text, key).toString(encUtf8);
}

export function getKeyFromPassword(password: string) {
  return SHA256(password).toString(encHex)
}