import React, { useState, useCallback } from "react";
import ApolloClient from "apollo-client";
import Cookies from "universal-cookie";

import LoginScreen from "./LoginScreen";
import NotesList from "./NotesList";
import "./App.css";
import { ApolloProvider } from "@apollo/react-hooks";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { persistCache } from 'apollo-cache-persist';
import NotificationSystem from "react-notification-system";
import Header from "./Header";

const cookies = new Cookies();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_ROOT + "graphql/graphql"
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

const cache = new InMemoryCache();
persistCache({
  cache,
  storage: window.localStorage as any,
});



const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

function App() {
  return (
    <ApolloProvider client={client}>
      <MainScreen />
    </ApolloProvider>
  );
}

function MainScreen() {
  const [userContext, setUserContext] = useState({
    username: cookies.get("username"),
    encryptionkey: cookies.get("encryptionkey")
  });

  const notificationSystem: any = React.createRef<any>();

  const logUser = useCallback(
    (username: string, token: string, encryptionkey: string) => {
      cookies.set("username", username, {
        path: "/"
      });
      localStorage.setItem("token", token);
      cookies.set("encryptionkey", encryptionkey, {
        path: "/"
      });
      client.resetStore();
      setUserContext({ username, encryptionkey });
    },
    [setUserContext]
  );

  const disconnect = useCallback(() => {
    cookies.remove("username", {
      path: "/"
    });
    localStorage.removeItem("token");
    cookies.remove("encryptionkey", {
      path: "/"
    });
    client.resetStore();
    setUserContext({ username: null, encryptionkey: null });
  }, [setUserContext]);

  const addNotification = useCallback(
    (event: { message: string; title: string; level: string }) => {
      const notification = notificationSystem.current;
      if (notification)
        notification.addNotification({
          message: event.message,
          title: event.title,
          level: event.level,
          autoDismiss: 2
        });
    },
    [notificationSystem]
  );

  return (
    <div className="App">
      <NotificationSystem ref={notificationSystem} />
      <div
        style={{ maxWidth: "1300px", marginLeft: "auto", marginRight: "auto" }}
      >
        <Header username={userContext.username} disconnect={disconnect} />
        {!userContext.username && (
          <LoginScreen logUser={logUser} addNotification={addNotification} />
        )}
        {userContext.username && (
          <NotesList
            username={userContext.username}
            addNotification={addNotification}
            encryptionkey={userContext.encryptionkey}
          />
        )}
      </div>
    </div>
  );
}

export default App;
