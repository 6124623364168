export function generatePassword(passwordField: HTMLInputElement) {
  var password =
    generateWord().toUpperCase() +
    ":" +
    generateWord() +
    ":" +
    generateWord() +
    ":" +
    generateWord();

  passwordField.value = password;
}

function generateWord() {
  var consonants = "zrtpqsdfjklmwxcvbn";
  var voyels = "aeuio";

  var word = "";

  if (Math.random() > 0.5) {
    word = consonants.charAt(Math.floor(Math.random() * consonants.length));
    word = word + voyels.charAt(Math.floor(Math.random() * voyels.length));
  } else {
    word = voyels.charAt(Math.floor(Math.random() * voyels.length));
    word =
      word + consonants.charAt(Math.floor(Math.random() * consonants.length));
  }

  word =
    word +
    (Math.floor(Math.random() * 10) + "" + Math.floor(Math.random() * 10));

  return word;
}
