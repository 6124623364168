import React, { useState, createRef } from "react";
import { MdExpandLess, MdExpandMore, MdEdit, MdDelete } from "react-icons/md";
import { useMutation } from "@apollo/react-hooks";
import { FaDice, FaClipboard } from "react-icons/fa";
import CopyToClipboard from "react-copy-to-clipboard";
import ReactHtmlParser from "react-html-parser";
import Moment from "react-moment";

import { NoteData } from "./NoteData";
import { UPDATE_NOTE, DELETE_NOTE } from "./Queries";
import { generatePassword } from "./generatePassword";
import { encrypt } from "./Encryption";

export default function NoteCard(props: {
  note: NoteData;
  reloadNotes: CallableFunction;
  addNotification: CallableFunction;
  encryptionkey: string;
}) {
  const note = props.note;
  const [expended, setExpanded] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);

  const [deleteNote] = useMutation(DELETE_NOTE);

  return (
    <div
      className={"p-2 col-12 col-md-6 col-lg-4 col-xl-3"}
      style={{ height: "min-content", textAlign: "left" }}
    >
      <div className={"lightbeigebg rounded p-2 d-flex"}>
        <div style={{ width: "100%" }}>
          <div
            onClick={e => setExpanded(!expended)}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                float: "left"
              }}
            >
              <b>{note.title}</b>
            </div>
            {!editMode && !expended && (
              <MdExpandMore
                onClick={e => setExpanded(true)}
                className="activeicon"
                style={{ float: "right" }}
              />
            )}
            {!editMode && expended && (
              <MdExpandLess
                onClick={e => setExpanded(false)}
                className="activeicon"
                style={{ float: "right" }}
              />
            )}
          </div>
          {editMode && (
            <NoteCardForm
              note={note}
              reloadNotes={props.reloadNotes}
              setEditMode={setEditMode}
              addNotification={props.addNotification}
              encryptionkey={props.encryptionkey}
            />
          )}

          {deleteMode && expended && (
            <div
              style={{ width: "100%", marginTop: "2px" }}
              className="d-flex justify-content-between"
            >
              <input
                className="btn btn-secondary"
                style={{ width: "90px", marginLeft: "auto" }}
                value="Cancel"
                type="button"
                onClick={e => setDeleteMode(false)}
              />
              <input
                className="btn"
                style={{
                  width: "90px",
                  marginLeft: "10px",
                  backgroundColor: "red",
                  color: "white"
                }}
                value="Confirm"
                type="button"
                onClick={e => {
                  deleteNote({
                    variables: {
                      id: note.id
                    }
                  }).then(e => {
                    props.reloadNotes();
                    setDeleteMode(false);
                    props.addNotification({
                      message: "Votre note a été supprimée",
                      level: "success"
                    });
                  });
                }}
              />
            </div>
          )}
          {!editMode && expended && (
            <div
              style={{ width: "100%", marginTop: "2px" }}
              className="d-flex justify-content-between mt-2"
            >
              <div style={{ width: "100%" }}>
                {!editMode && (
                  <MdDelete
                    onClick={e => {
                      setDeleteMode(true);
                      setEditMode(false);
                    }}
                    className="activeicon"
                    style={{ float: "right", color: "red" }}
                  />
                )}

                <MdEdit
                  onClick={e => {
                    setEditMode(true);
                    setDeleteMode(false);
                  }}
                  className="activeicon"
                  style={{ float: "right" }}
                />
                {note.login !== "" && (
                  <div>
                    <CopyToClipboard
                      text={note.login}
                      onCopy={e => {
                        props.addNotification({
                          message: "Le login a été copié",
                          level: "success"
                        });
                      }}
                    >
                      <FaClipboard
                        className="activeicon"
                        style={{ margin: "1px" }}
                      />
                    </CopyToClipboard>
                    <b>Login : </b>
                    {note.login}
                  </div>
                )}
                {note.password !== "" && (
                  <div>
                    <CopyToClipboard
                      text={note.password}
                      onCopy={e => {
                        props.addNotification({
                          message: "Le mot de passe a été copié",
                          level: "success"
                        });
                      }}
                    >
                      <FaClipboard
                        className="activeicon"
                        style={{ margin: "1px" }}
                      />
                    </CopyToClipboard>
                    <b>Mot de passe : </b>
                    {note.password}
                  </div>
                )}
                {note.text !== "" && <div>{ReactHtmlParser(note.text)}</div>}
                <div>
                  <i style={{ fontSize: "small" }}>
                    Créée le :{" "}
                    <Moment format="DD/MM/YYYY" date={note.createdDate} /> -
                    Modifiée le :{" "}
                    <Moment format="DD/MM/YYYY" date={note.updatedDate} />
                  </i>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function NoteCardForm(props: {
  note: NoteData;
  setEditMode: CallableFunction;
  reloadNotes: CallableFunction;
  addNotification: CallableFunction;
  encryptionkey: string;
}) {
  const note = props.note;

  let titleField = createRef<HTMLInputElement>();
  let loginField = createRef<HTMLInputElement>();
  let passwordField = createRef<HTMLInputElement>();
  let textField = createRef<HTMLTextAreaElement>();

  const [updateNote] = useMutation(UPDATE_NOTE);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        let title: string = "";
        let login: string = "";
        let password: string = "";
        let text: string = "";

        if (titleField.current) title = titleField.current.value;
        if (loginField.current) login = loginField.current.value;
        if (passwordField.current) password = passwordField.current.value;
        if (textField.current) text = textField.current.value;

        updateNote({
          variables: {
            id: note.id,
            title: encrypt(title, props.encryptionkey),
            login: encrypt(login, props.encryptionkey),
            password: encrypt(password, props.encryptionkey),
            text: encrypt(text, props.encryptionkey)
          }
        }).then(e => {
          props.reloadNotes();
          props.setEditMode(false);
          props.addNotification({
            message: "La note a été mise à jour",
            level: "success"
          });
        });
      }}
    >
      <div
        style={{ width: "100%" }}
        className="d-flex justify-content-between mt-2"
      >
        <b>Note title</b>
        <input
          className="form-control form-control-sm"
          style={{ width: "200px", marginLeft: 10 }}
          name="title"
          type="text"
          defaultValue={note.title}
          ref={titleField}
        />
      </div>
      <div
        style={{ width: "100%" }}
        className="d-flex justify-content-between mt-2"
      >
        Login
        <input
          className="form-control form-control-sm"
          style={{ width: "200px", marginLeft: 10 }}
          name="login"
          type="text"
          defaultValue={note.login}
          ref={loginField}
        />
      </div>
      <div
        style={{ width: "100%" }}
        className="d-flex justify-content-between mt-2"
      >
        Password
        <FaDice
          className="activeicon ml-2"
          onClick={e => {
            if (passwordField.current) generatePassword(passwordField.current);
          }}
        />
        <input
          className="form-control form-control-sm"
          style={{ width: "200px", marginLeft: 10 }}
          name="password"
          type="text"
          defaultValue={note.password}
          ref={passwordField}
        />
      </div>
      <div
        style={{ width: "100%" }}
        className="d-flex justify-content-between mt-2"
      >
        Text
        <textarea
          className="form-control form-control-sm"
          style={{ width: "200px", marginLeft: 10 }}
          name="text"
          defaultValue={note.text}
          ref={textField}
        />
      </div>
      <div
        style={{ width: "100%" }}
        className="d-flex justify-content-between mt-2"
      >
        <input
          className="btn btn-secondary"
          style={{ width: "90px", marginLeft: "auto" }}
          value="Cancel"
          type="button"
          onClick={e => props.setEditMode(false)}
        />
        <input
          className="btn btn-primary"
          style={{ width: "90px", marginLeft: "10px" }}
          value="Save"
          type="submit"
        />
      </div>
    </form>
  );
}
