import gql from "graphql-tag";

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      username,token
    }
  }
`;

export const REGISTER = gql`
  mutation register($username: String!, $password: String!, $email: String!) {
    register(username: $username, password: $password, email: $email) {
      username,token
    }
  }
`;

export const GET_USER = gql`
  query user($username: String!) {
    user(username: $username) {
      username,
      email
    }
  }
`;

export const GET_NOTES = gql`
  query notes {
    notes {
      id
      title
      login
      password
      text
      createdDate
      updatedDate
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation createNote(
    $title: String!
    $login: String
    $password: String
    $text: String
  ) {
    createNote(title: $title, login: $login, password: $password, text: $text) {
      id
      title
      login
      password
      text
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation updateNote(
    $id: ID!
    $title: String!
    $login: String
    $password: String
    $text: String
  ) {
    updateNote(
      id: $id
      title: $title
      login: $login
      password: $password
      text: $text
    ) {
      id
      title
      login
      password
      text
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation deleteNote($id: ID!) {
    deleteNote(id: $id)
  }
`;
