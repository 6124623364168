import React from "react";
import { IoMdLogOut } from "react-icons/io";
import logo_vault from "./img/logo192.png";

export default function Header(props: {
  username: string;
  disconnect: CallableFunction;
}) {
  return (
    <section className="page-section clearfix">
      <h1>
        <img
          src={logo_vault}
          style={{
            height: "64px",
            width: "64px",
            marginRight: "5px",
            verticalAlign: "middle"
          }}
          alt=""
        />
        Crypte
      </h1>
      {props.username && (
        <h2>
          Connecté en tant que {props.username}
          <IoMdLogOut
            onClick={e => props.disconnect()}
            className="activeicon"
          />
        </h2>
      )}
    </section>
  );
}
