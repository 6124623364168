import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import { decrypt } from "./Encryption";
import AddNote from "./AddNote";
import NoteCard from "./NoteCard";
import { NoteData } from "./NoteData";
import ReactPaginate from 'react-paginate';

import { GET_NOTES, GET_USER } from "./Queries";

type NotesProps = {
  username: string;
  addNotification: CallableFunction;
  encryptionkey: string;
};

export default function NotesList({
  username,
  addNotification,
  encryptionkey,
}: NotesProps) {
  const notesReturn = useQuery(GET_NOTES, { errorPolicy: "ignore" });
  const userReturn = useQuery(GET_USER, {
    variables: { username: username, errorPolicy: "ignore" },
  });
  let userEmail = null;
  if (userReturn.data) userEmail = userReturn.data.user.email;

  const [searchTerm, setSearchTerm] = useState("");
  const [pageNum, setPageNum] = useState(0);

  function handlePageClick(data:{selected:number}) {
    setPageNum(data.selected);
  }

  if (notesReturn.loading) return <p>Chargement des notes...</p>;
  if (!notesReturn.data || !notesReturn.data.notes)
    return <p>Aucune note trouvé</p>;

  const notes: NoteData[] = notesReturn.data.notes.map((note: NoteData) => {
    const decryptedNote: NoteData = {
      id: note.id,
      title: "",
      login: "",
      password: "",
      text: "",
      createdDate: note.createdDate,
      updatedDate: note.updatedDate,
    };
    if (note.title) decryptedNote.title = decrypt(note.title, encryptionkey);
    if (note.login) decryptedNote.login = decrypt(note.login, encryptionkey);
    if (note.password)
      decryptedNote.password = decrypt(note.password, encryptionkey);
    if (note.text) decryptedNote.text = decrypt(note.text, encryptionkey);
    return decryptedNote;
  });

  const filteredNotes: NoteData[] = notes.filter(
    (note: NoteData) =>
      !note.title.toLowerCase().search(".*" + searchTerm.toLowerCase())
  );

    const slicedNotes = filteredNotes.slice(pageNum*12,(pageNum+1)*12);

  return (
    <div>
      <section
        className="page-section clearfix container"
        style={{ maxWidth: "1300px" }}
      >
        <div className="row  justify-content-between">
          <AddNote
            reloadNotes={notesReturn.refetch}
            addNotification={addNotification}
            userEmail={userEmail}
            encryptionkey={encryptionkey}
          />

          <div style={{height:"min-content"}} className="lightbeigebg rounded p-2 m-2 mobilefullwidth">
            <ReactPaginate        
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={(filteredNotes.length/12)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </div>

          <SearchBar searchTerm={searchTerm} updateSearch={setSearchTerm} nbnotes={notes.length} nbfilterednotes={filteredNotes.length} />
        </div>
        </section>
      <section
        className="page-section clearfix container"
        style={{ maxWidth: "1300px" }}
      >
        <div className="row">
          {slicedNotes
            .map((note: any) => (
              <NoteCard
                key={note.id}
                note={note}
                reloadNotes={notesReturn.refetch}
                addNotification={addNotification}
                encryptionkey={encryptionkey}
              />
            ))}
        </div>
      </section>
    </div>
  );
}

type SearchData = {
  searchTerm: string;
  updateSearch: CallableFunction;
  nbnotes: number;
  nbfilterednotes: number;
};

function SearchBar(props: SearchData) {
  const searchData: SearchData = props;

  return (
    <div
      className={"lightbeigebg rounded p-2 m-2 mobilefullwidth"}
      style={{
        textAlign: "left",
        height: "min-content",
        display: "flex",
        minWidth: "380px",
      }}
    >
      <b>Filtrer: </b>
      <input
        className="form-control form-control-sm"
        style={{ width: "100%", marginLeft: 10 }}
        name="username"
        type="text"
        value={searchData.searchTerm}
        onChange={(e) => searchData.updateSearch(e.target.value)}
      />
      <div style={{width:"100px", textAlign:"right"}}>
      <em>{props.nbfilterednotes}/{props.nbnotes}</em>
      </div>
    </div>
  );
}
