import React, { createRef, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import "react-toggle/style.css";
import ReactToggle from "react-toggle";
import * as bcrypt from 'bcryptjs';

import { LOGIN, REGISTER } from "./Queries";
import { getKeyFromPassword } from "./Encryption";

const hash = "$2b$10$3euPcmQFCiblsZeEu5s7p.9OVHgeHWFDk9nhMqZ0m/3pd/lhwZgES";

export default function LoginScreen(props: {
  logUser: CallableFunction;
  addNotification: CallableFunction;
}) {
  const [registerMode, setRegisterMode] = useState(false);

  let usernameField = createRef<HTMLInputElement>();
  let passwordField = createRef<HTMLInputElement>();

  let passwordConfirmField = createRef<HTMLInputElement>();
  let emailField = createRef<HTMLInputElement>();

  const [submitedPassword, setSubmitedPassword] = useState("");

  const [logincall] = useMutation(LOGIN, {
    onCompleted({ login }) {
      props.logUser(
        login.username,
        login.token,
        getKeyFromPassword(submitedPassword)
      );
      props.addNotification({
        message: "Vous êtes maintenant connecté",
        level: "success"
      });
    }
  });

  const [registercall] = useMutation(REGISTER, {
    onCompleted({ register }) {
      props.logUser(
        register.username,
        register.token,
        getKeyFromPassword(submitedPassword)
      );
      props.addNotification({
        message: "Votre compte a été créé",
        level: "success"
      });
    }
  });

  return (
    <div style={{ width: "100%" }}>
      <section
        className="page-section clearfix d-flex"
        style={{
          width: "310px",
          flexWrap: "wrap",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2em"
        }}
      >
        <div style={{ display: "flex" }}>
          <ReactToggle
            checked={registerMode}
            onChange={e => setRegisterMode(!registerMode)}
            aria-labelledby="registerModeToggle-label"
            id={"registerModeToggle"}
          />
          <div
            id="registerModeToggle-label"
            style={{
              marginLeft: "5px",
              marginRight: "5px",
              verticalAlign: "middle"
            }}
          >
            Créer un compte
          </div>
        </div>

        <form>
          <div
            style={{ width: "100%" }}
            className="d-flex justify-content-between mt-2"
          >
            Login
            <input
              className="form-control form-control-sm"
              style={{ width: "200px", marginLeft: 10 }}
              name="username"
              type="text"
              ref={usernameField}
            />
          </div>
          <div
            style={{ width: "100%" }}
            className="d-flex justify-content-between mt-2"
          >
            Mot de passe
            <input
              className="form-control form-control-sm"
              style={{ width: "200px", marginLeft: 10 }}
              name="password"
              type="password"
              ref={passwordField}
            />
          </div>
          {!registerMode && (
            <div
              style={{ width: "100%" }}
              className="d-flex justify-content-between mt-2"
            >
              <input
                className="btn btn-primary"
                style={{ width: "150px", marginLeft: "auto" }}
                value="Login"
                type="button"
                onClick={async e => {
                  e.preventDefault();
                  let username: string = "";
                  let password: string = "";

                  if (usernameField.current)
                    username = usernameField.current.value;
                  if (passwordField.current)
                    password = passwordField.current.value;

                  setSubmitedPassword(password);

                  //const hash1 = await bcrypt.hash(password, hash);
                  //const hash2 = await bcrypt.hash(hash1, 10);
                  //console.log(hash2);

                  logincall({
                    variables: { username: username, password: await bcrypt.hash(password, hash) }
                  });
                }}
              />
            </div>
          )}
          {registerMode && (
            <div>
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-between mt-2"
              >
                Confirmer
                <input
                  className="form-control form-control-sm"
                  style={{ width: "200px", marginLeft: 10 }}
                  name="passwordConfirm"
                  type="password"
                  ref={passwordConfirmField}
                />
              </div>
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-between mt-2"
              >
                Email
                <input
                  className="form-control form-control-sm"
                  style={{ width: "200px", marginLeft: 10 }}
                  name="email"
                  type="text"
                  ref={emailField}
                />
              </div>
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-between mt-2"
              >
                <input
                  className="btn btn-primary"
                  style={{ width: "150px", marginLeft: "auto" }}
                  value="Créer"
                  type="button"
                  onClick={async e => {
                    e.preventDefault();
                    let username: string = "";
                    let password: string = "";
                    let passwordConfirm: string = "";
                    let email: string = "";

                    if (usernameField.current)
                      username = usernameField.current.value;
                    if (passwordField.current)
                      password = passwordField.current.value;

                    if (passwordConfirmField.current)
                      passwordConfirm = passwordConfirmField.current.value;

                    if (emailField.current) email = emailField.current.value;

                    if (username === "") {
                      props.addNotification({
                        message: "Le nom d'utilisateur est obligatoire",
                        level: "error"
                      });
                      return;
                    }

                    if (password === "") {
                      props.addNotification({
                        message: "Le mot de passe est obligatoire",
                        level: "error"
                      });
                      return;
                    }

                    if (password !== passwordConfirm) {
                      props.addNotification({
                        message: "Les mots de passe ne sont pas identiques",
                        level: "error"
                      });
                      return;
                    }

                    if (!emailValidation("" + email)) {
                      props.addNotification({
                        message: "L'email saisi n'est pas valide",
                        level: "error"
                      });
                      return;
                    }

                    registercall({
                      variables: {
                        username: username,
                        password: await bcrypt.hash(password, hash),
                        email: email
                      }
                    });
                  }}
                />
              </div>
            </div>
          )}
        </form>
      </section>
    </div>
  );
}

const emailValidation = (email: string) => {
  const expression = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i;

  return expression.test(String(email).toLowerCase());
};
